import React, { useState } from "react";
import OneDrive from "../../components/onedrive";


const Documents = () => {
    return (
        <div className="mt-14">
            <OneDrive />
        </div>
    );
}

export default Documents;